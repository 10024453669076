import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap'
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  resetQuickQuoteResults
} from '../../../../store/actions/userActions'
import { processFlow } from '../../../common/Homepage/TPApiCall'

function QuotelistModel ({
  show,
  setModel,
  handleFilteredResult,
  motorQuotation
}) {
  const motorRequest = useSelector(state => state?.root?.motorRequest)
  const QuickQuoteResult = useSelector(state => state?.root?.QuickQouteResult)
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )
  const list = [0, 20, 25, 35, 45, 50]
  const toggle = () => setModel(!show)
  const [select, setSelect] = useState(
    !motorRequest?.isPreviousInsuranceClaimed
      ? 'no'
      : motorRequest?.isPreviousInsuranceClaimed
      ? 'yes'
      : ''
  )
  const dispatch = useDispatch()

  const [bonus, setBonus] = useState(motorRequest?.prevNoClaimBonus)

  const handleNCB = () => {
    let newStructureData = { ...motorRequest }
    dispatchMotorQuote('prevNoClaimBonus', bonus)
    const previousClaimData = {
      isPreviousInsuranceClaimed: motorRequest?.isPreviousInsuranceClaimed,
      prevNoClaimBonus: bonus
    }
    dispatchProcessFlowMotor('step7.previousClaimData', previousClaimData)
    const postData = {
      ...processFlowObjMotor,
      step: 'step7',
      motorRequest: { ...motorRequest },
      step7: {
        ...processFlowObjMotor.step7,
        previousClaimData: previousClaimData
      }
    }
    processFlow(postData)
    if (select === 'no') {
      newStructureData = {
        ...newStructureData,
        prevNoClaimBonus: bonus
      }
      dispatch(resetQuickQuoteResults())
      motorQuotation(newStructureData)
      toggle()
    } else {
      const previousNoClaimBonus = 0

      newStructureData = {
        ...newStructureData,
        prevNoClaimBonus: previousNoClaimBonus
      }
      dispatch(resetQuickQuoteResults())
      motorQuotation(newStructureData)
      toggle()
    }
  }

  return (
    <Modal 
    isOpen={show} 
    toggle={toggle} 
    centered size='md'
    backdrop = "static"
    >
      <ModalBody>
        <div className='gsc_modalWrapper'>
          <div className='modal-logo'>
            <div className='modal-logo'>
              <a>
                <img src='/assets/images/ex_new_2.png' alt='' class='w-100' />
              </a>
            </div>
            <div className='modal_heading w-100 mb-3'>
              <h4 className='text-center'>Confirm NCB</h4>

              <div onClick={toggle} class='v2_close false'>
                <a>
                  <img
                    src='/assets/images/delete_icon.svg'
                    title='Close'
                    alt='close'
                  />
                </a>
              </div>
            </div>
            <div className='mb-3'>

                <div className='mb-3'>
                  <h6 class='table bold fs-3 mb-3'>
                    Have you made a claim in the previous year?
                  </h6>
                  <div className='d-flex justify-content-around align-items-center confirm-checkbox mt-3 pt-2'>
                    <label
                      onClick={() => {
                        setSelect('yes')
                        dispatchMotorQuote('isPreviousInsuranceClaimed', true)
                      }}
                      style={{
                        border:
                          select == 'yes'
                            ? '3px solid #00b0cb'
                            : '1px solid grey',
                        width: '40%',
                        borderRadius: '5px',
                        padding: '5px 0',
                        fontSize: '20px'
                      }}
                      className='d-flex justify-content-center align-items-center'
                    >
                      Yes
                    </label>
                    <label
                      onClick={() => {
                        setSelect('no')
                        dispatchMotorQuote('isPreviousInsuranceClaimed', false)
                      }}
                      style={{
                        border:
                          select == 'no'
                            ? '3px solid #00b0cb'
                            : '1px solid grey',
                        width: '40%',
                        borderRadius: '5px',
                        padding: '5px 0',
                        fontSize: '20px'
                      }}
                      className='d-flex justify-content-center align-items-center'
                    >
                      No
                    </label>
                  </div>
                </div>

              {select == 'no' && (
                <div className=''>
                  <h3 className='fs-3 mt-4 pt-3'>
                    Select your Existing NCB (No Claim Bonus)
                  </h3>
                  <p className='fs-4 mt-2'>
                    This is mentioned in your previous policy document
                  </p>
                  <div className='ul-percent mt-3'>
                    {list?.map(res => {
                      return (
                        <p onClick={() => setBonus(res)}>
                          <label className={bonus == res ? 'selected' : ''}>
                            <span>{res}%</span>
                          </label>
                        </p>
                      )
                    })}
                  </div>
                </div>
              )}
              <div class='ncbmsg mt-3'>
                {' '}
                <img src='/assetss/images/v2_bulbyellow.svg' alt='Idea' /> No
                Claim Bonus (NCB) is a discount offered on Own Damage (OD)
                premium for not claiming on your car insurance during the policy
                period. Learn More
              </div>
              <div class='v2_btngrp mb-3'>
                <div class='wrapper'>
                  <button
                    onClick={() => handleNCB()}
                    type='submit'
                    name='submitBtn'
                    class='button submitButton gsc_col-xs-12 gsc_col-md-12'
                  >
                    {QuickQuoteResult.length > 0
                      ? 'Update Quotes'
                      : 'Get Quotes'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default QuotelistModel
