import React, { useEffect, useState } from "react";
import Header from "../Header";
import {
  getKycStatus,
  getPreviousJourneyDetails,
  processFlow,
} from "../Homepage/TPApiCall";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { extractCustomerLeadId } from "../../utility/constants";
import {dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  selectedPlanAction,} from "../../../store/actions/userActions";

const KycRedirectionPage = () => {
  const [kycRedirection, setKycRedirection] = useState(false);

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  console.log("location", location);

  const urlCustomerLeadId = extractCustomerLeadId(location?.pathname);
  console.log("urlCustomerLeadId", urlCustomerLeadId);

  const kycRedirectStatus = async (motorRequest, selectedPlan, stepsData) => {
    console.log("stepsData", stepsData);
    try {
      const response = await getKycStatus(motorRequest, selectedPlan);
      if (response?.data?.kycStatus === "success") {
        setKycRedirection(false);
        dispatchMotorQuote("kycStatus", "completed");
        dispatchMotorQuote("dob", response?.data?.kycData?.dob);
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: { ...motorRequest },
          processDiscription: "kycAndUserDetails",
          step: "step8",
          step8: {
            ...processFlowObjMotor.step8,
            motorRequest: { ...motorRequest },
            kycAndUserDetails: {
              ...stepsData?.step8?.kycAndUserDetails,
              kycStatus: "completed",
              dob: response?.data?.kycData?.dob,
            },
            kycConfig: { ...stepsData?.step8?.kycConfig },
            planDetails: selectedPlan,
          },
        };
        processFlow(dataForFlow);
        setTimeout(() => {
          navigate(`/proposal/${motorRequest?.customerLeadId}`, {
            state: { kycStatus: true },
          });
        }, [2000]);
      } else {
        setKycRedirection(true);
        dispatchMotorQuote("kycStatus", "failure");
        navigate(`/proposal/${motorRequest?.customerLeadId}`, {
          state: { kycStatus: false },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const updateJourneyData = (stepsData, processStep, processDiscription) => {
    console.log("stepsData?.step8?.kycConfig", stepsData?.step8?.kycConfig);
    dispatchKycConfig(stepsData?.step8?.kycConfig);
    dispatchProcessFlowMotor("step8", stepsData?.step8);

    for (let key in stepsData?.step8?.motorRequest) {
      dispatchMotorQuote(`${key}`, stepsData?.step8?.motorRequest[key]);
    }

    for (let key in stepsData?.step8?.kycAndUserDetails) {
      dispatchMotorQuote(`${key}`, stepsData?.step8?.kycAndUserDetails[key]);
    }
    dispatch(selectedPlanAction(stepsData?.step8?.planDetails));
  };
  useEffect(() => {
    getPreviousJourneyDetails({ customerLeadId: urlCustomerLeadId }).then(
      (response) => {
        console.log("getPreviousJourneyDetails", response);
        const motorRequest = response?.data?.stepsData?.step8?.motorRequest;
        const selectedPlan = response?.data?.stepsData?.step8?.planDetails;
        const stepsData = response?.data?.stepsData;

        updateJourneyData(
          stepsData,
          response?.data?.processStep,
          response?.data?.processDiscription
        );
        setTimeout(() => {
          kycRedirectStatus(motorRequest, selectedPlan, stepsData);
        }, [3000]);
      }
    );
    // kycRedirectStatus();
  }, []);

  return (
    <>
      <Header />
      <div
        className="card text-center"
        style={{
          width: "100%",
          height: "100%",
          marginTop: "3rem",
          overflow: "hidden",
        }}
      >
        <div>
          <div className="js-container"></div>
          <div
            style={{
              textAlign: "center",
              //   marginTop: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: "100%",
              height: "100vh",
              //   overflow: "hidden",
              top: "100px",
              left: "0px",
            }}
          >
            {kycRedirection ? (
              <div className="loader_circle">
                <div class="payment-loader kyc-redirection-loader">
                  <div class="pad">
                    <div class="chip"></div>
                    <div class="line line1"></div>
                    <div class="line line2"></div>
                  </div>
                  <div class="loader-text">
                    Your KYC has been failed. Redirecting you to our Portal...
                  </div>
                </div>
              </div>
            ) : (
              <div className="loader_circle">
                <div class="payment-loader">
                  <div class="pad">
                    <div class="chip"></div>
                    <div class="line line1"></div>
                    <div class="line line2"></div>
                  </div>
                  <div class="loader-text">Your KYC has been Successful</div>
                </div>
              </div>
            )}

            {/* <div className="checkmark-circle">
                  <div className="background"></div>
                  <div className="checkmark draw"></div>
                </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default KycRedirectionPage;
